import * as CSDS from '@csscompany/csds-components';
import { getUrlParamFromSessionStorage } from '@publicss/utils';
import { isBrowser } from '@publicss/utils';
import { PopupButton } from '@typeform/embed-react';
import classNames from 'classnames';
import React, { PropsWithChildren, useCallback } from 'react';

import {
  BOOK_TOUR_COMPONENT,
  LOG_PRODUCT_NAME,
} from '../../constants/facilityConstants';
import { useUTMCookies } from '../../hooks/useUTMCookies';
import { usePageContext } from '../../providers/PageContext';
import { isAnyFacilityPage } from '../../utils/kitchen-growth-helpers';
import { useMixpanel } from '../../utils/mixpanel';
import { ButtonProps, buttonSizeClasses } from './Button';

type TypeformButtonProps = Omit<ButtonProps, 'href' | 'children'> &
  TypeformButtonWrapperProps & {
    buttonClassName?: string;
    typeform_id?: string;
  };

/**
 * A wrapper around Button that opens a typeform modal
 */
export const TypeformButton: React.FunctionComponent<TypeformButtonProps> = ({
  children,
  className = '',
  buttonClassName,
  size = 'md',
  id,
  typeform_id,
  utm_content,
  fullWidth = false,
  ...delegated
}) => {
  const { global, siteTag } = usePageContext();
  const isCK = siteTag === 'site:cloudkitchens';

  if (!typeform_id) {
    typeform_id = global.typeform_id;
  }

  return (
    <TypeformButtonWrapper
      id={id}
      className={className}
      typeform_id={typeform_id}
      fullWidth={fullWidth}
      utm_content={utm_content}>
      <CSDS.Button
        className={classNames(buttonSizeClasses[size], buttonClassName, {
          typeform_cta: isCK,
        })}
        fullWidth={fullWidth}
        {...delegated}>
        {children}
      </CSDS.Button>
    </TypeformButtonWrapper>
  );
};

interface TypeformButtonWrapperProps extends PropsWithChildren {
  className?: string;
  id?: string;
  utm_content?: string;
  fullWidth?: boolean;
  typeform_id: string;
}

const TypeformButtonWrapper: React.FunctionComponent<
  TypeformButtonWrapperProps
> = ({
  children,
  className,
  fullWidth = false,
  id,
  typeform_id,
  utm_content: propsUtmContent,
}) => {
  const mixpanelClient = useMixpanel();
  const utms = useUTMCookies();

  const { global, siteTag, siteURL } = usePageContext();
  const isCK = siteTag === 'site:cloudkitchens';
  const isBookitEnabled = [
    'site:chefcollective',
    'site:citychefs',
    'site:cookcity',
    'site:cooklane',
    'site:dishmix',
    'site:foodstars',
    'site:freshlane',
    'site:kitchenbase',
    'site:kitchennow',
    'site:kitchenpark',
    'site:pedidoslab',
    'site:smartcity-kitchens',
  ].includes(siteTag);
  const { open_typeform_in_new_tab: shouldTypeformBeOpenedInNewTab } = global;

  const urlSearchParams = isBrowser()
    ? new URLSearchParams(window.location.search)
    : undefined;

  const urlString = !isBrowser()
    ? siteURL
    : sessionStorage.getItem('landing_url') !== null
      ? sessionStorage.getItem('landing_url')?.toString() || ''
      : window.location.href;

  const url = isBrowser() ? new URL(urlString) : new URL(siteURL);

  const ckHiddenFields = isCK
    ? {
        utm_content:
          getUrlParamFromSessionStorage('utm_content') ||
          propsUtmContent ||
          utms.utm_content ||
          'Direct',
        utm_source: urlSearchParams?.get('utm_source') || utms.utm_source || '',
        utm_medium:
          getUrlParamFromSessionStorage('utm_medium') || utms.utm_medium || '',
        utm_campaign: isAnyFacilityPage()
          ? 'Facility Finder'
          : urlSearchParams?.get('utm_campaign') ||
            utms.utm_campaign ||
            'Facility Finder',
        utm_term: urlSearchParams?.get('utm_term') || utms.utm_term || '',
        utm_geo: urlSearchParams?.get('utm_geo') || utms.utm_geo || '',
        newlp: urlString,
      }
    : {};

  const transitiveSearchParams =
    global?.typeform_transitive_search_params
      ?.split(',')
      ?.map((p) => p.trim()) || undefined;

  const hiddenFields =
    global?.typeform_hidden_fields?.split(',')?.reduce(
      (acc, curr) => {
        const split = curr.split('=');
        const key = split[0].trim();
        let value = split[1]?.trim();
        if (!value) value = urlSearchParams?.get(key) || utms[key] || '';
        acc[key] = value;
        return acc;
      },
      {} as Record<string, string>
    ) || {};

  const tf_variable = urlSearchParams?.get('tf_variable');
  if (tf_variable) {
    hiddenFields.tf_variable = tf_variable;
  }

  const bookitHiddenFields =
    isBookitEnabled && isBrowser()
      ? {
          ld_bookit_log_id: window.localStorage.getItem(
            'LDBookItV2_tempSavedUID'
          ),
        }
      : {};

  // Extract geolocation parameters if available
  const geoFields =
    isBrowser() && window.geolocation_results
      ? {
          ip: window.geolocation_results.ip,
          city: window.geolocation_results.city,
          region: window.geolocation_results.region,
          country: window.geolocation_results.country,
          latitude: window.geolocation_results.latitude,
          longitude: window.geolocation_results.longitude,
          zip: window.geolocation_results.zip,
        }
      : {};

  const closestCityResult =
    isBrowser() && window.closestCityResult
      ? {
          ins_city: window.closestCityResult,
        }
      : {};

  const typeformProps = {
    id: typeform_id,
    transitiveSearchParams,
    hidden: {
      ...hiddenFields,
      ...ckHiddenFields,
      ...bookitHiddenFields,
      ...geoFields,
      ...closestCityResult,
      _gl: '',
      referrer: '',
    },
    size: 100,
  };

  const generateComplexGlParam = () => {
    if (!isBrowser()) return null;

    try {
      const gaCookie = document.cookie.match(/_ga=([^;]+)/)?.[1];
      const gclAwCookie = document.cookie.match(/_gcl_aw=([^;]+)/)?.[1];
      const gclAuCookie = document.cookie.match(/_gcl_au=([^;]+)/)?.[1];

      const allCookies = document.cookie.split(';');
      let gaSessionCookie = '';
      for (const cookie of allCookies) {
        if (cookie.trim().startsWith('_ga_')) {
          const parts = cookie.trim().split('=');
          if (parts.length > 1) {
            const gaSessionKey = parts[0].trim();
            const gaSessionValue = parts[1].trim();
            gaSessionCookie = `${gaSessionKey.substring(1)}*${gaSessionValue}`;
            break;
          }
        }
      }

      const glParts = ['1'];

      const randomHash = Math.random().toString(36).substring(2, 8);
      glParts.push(randomHash);

      if (gclAwCookie) {
        glParts.push('_gcl_aw');
        glParts.push(gclAwCookie);
      }

      if (gclAuCookie) {
        glParts.push('_gcl_au');
        glParts.push(gclAuCookie);
      }

      if (gaCookie) {
        glParts.push('_ga');
        glParts.push(gaCookie);
      }

      if (gaSessionCookie) {
        glParts.push(gaSessionCookie);
      }

      return glParts.join('*');
    } catch (e) {
      console.error('Error generating complex _gl parameter:', e);
      return null;
    }
  };

  if (isBrowser()) {
    const glParam = urlSearchParams?.get('_gl');

    if (glParam) {
      typeformProps.hidden._gl = glParam;
    } else {
      typeformProps.hidden._gl = generateComplexGlParam();
    }

    if (document.referrer) {
      typeformProps.hidden.referrer = document.referrer;
    } else {
      typeformProps.hidden.referrer = window.location.href;
    }
  }

  const onBookTour = useCallback(
    (event) => {
      event.stopPropagation();
      mixpanelClient.track(
        {
          product: LOG_PRODUCT_NAME,
          component: BOOK_TOUR_COMPONENT,
          event: 'Book Tour Clicked',
        },
        {
          position: `${url}`,
        }
      );
      if (shouldTypeformBeOpenedInNewTab && typeform_id) {
        const searchParams = new URLSearchParams(
          typeformProps.hidden
        ).toString();

        window.open(
          `https://kitchen-request.typeform.com/to/${typeform_id}?${searchParams}`,
          '_self',
          'noopener'
        );
      }
    },
    [
      mixpanelClient,
      shouldTypeformBeOpenedInNewTab,
      typeform_id,
      typeformProps.hidden,
      url,
    ]
  );

  const wrapperClassNames = classNames(
    'rounded-full',
    fullWidth ? 'w-full' : 'w-fit',
    className
  );

  if (!typeform_id) return null;

  return (
    <div
      className={wrapperClassNames}
      id={id ?? undefined}
      onClick={onBookTour}>
      {shouldTypeformBeOpenedInNewTab ? (
        <button className={wrapperClassNames}>{children}</button>
      ) : (
        <PopupButton as="div" className={wrapperClassNames} {...typeformProps}>
          {children}
        </PopupButton>
      )}
    </div>
  );
};
